<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12" class="py-2 px-2 table-view">
                <platon-table
                    :table-name="tableName"
                    :table-filters="filters"
                    table-classes="card p-2 mb-0"
                    ref="table"
                    :is-public="isPublic"
                    @onMetaLoaded="onMetaLoaded"
                    :control-buttons="true"
                    :pagination-enabled="true"
                    :show-header="true"
                    :show-filters="true"
                    :navigate-by-keyboard="true"
                    @onFiltersChange="onFiltersChange"
                    @onRowDblClick="eventSender('onRowDblClick', ...arguments)"
                    @onRowClick="eventSender('onRowClick', ...arguments)"
                    @onRowHover="eventSender('onRowHover', ...arguments)"
                    @rowSelected="eventSender('rowSelected', ...arguments)"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import TitleMixin from "@Platon/mixins/TitleMixin"

export default {
    name: "PlatonTableView",

    mixins: [TitleMixin],

    data() {
        let filters = { ...this.$route.query } || {}

        return {
            filters,
            isPublic: this.$route.meta["publicRoute"] === true,
            tableName: this.$route.params.name
        }
    },

    methods: {
        onFiltersChange(filters) {
            let route = this.$router.resolve({
                params: this.$route.params,
                query: filters
            })

            if (this.$route.fullPath !== route.resolved.fullPath) {
                this.$router.push(route.resolved)
            }
        },

        /**
         * @param {PlatonTable} meta
         */
        onMetaLoaded(meta) {
            this.setTitle(meta.title)
        },

        eventSender(event, index, data) {
            this.$sendEvent(event, index, data)
        }
    }
}
</script>
